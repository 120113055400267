import React, { useReducer, useContext } from 'react';
import openingsreducer from '../reducers/openings';


const OpeningsDataContext = React.createContext();

export const OpeningsDataProvider = ({ children }) => {

    const [state, dispatch] = useReducer(openingsreducer, {
        weeklyOpeningsData: undefined,
        specialOpeningsData: undefined,
        // newOpeningData: {
        //     new_date: {
        //         closed: false,
        //         closing1: '',
        //         closing2: '',
        //         opening1: '',
        //         opening2: ''
        //     }
        // }
    });

    return (
        <OpeningsDataContext.Provider value={{ state, dispatch }}>
            { children}
        </OpeningsDataContext.Provider>
    )
}

export const useStore = () => useContext(OpeningsDataContext);