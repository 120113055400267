import axios from 'axios';
import { STORE_INFO_URL } from '../constants/links';


const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getMockupDataFetchFunction = (data) => async (ms) => {
  await delay(ms);
  return data;
};



export const fetchStoreData = async () => {
  const res = await axios.get(STORE_INFO_URL, { withCredentials: true });
  return res.data;
  // await delay(300);
  // return { name: 'Demo Name', address: 'Demo Address'}
};

export const synchronizeDataFetch = (fetchDataCallback, ...args) => async () => {
  const p1 = fetchStoreData();
  const p2 = fetchDataCallback(...args);
  const res = await Promise.all([p1, p2]);
  return {
    storeInfo: res[0],
    data: res[1],
  };
};
