

import {
    UPDATE_WEEKLY_OPENINGS_DATA,
    UPDATE_SPECIAL_OPENINGS_DATA,
    UPDATE_NEW_OPENING_DATA
} from '../actions/openings';


export default (state, action) => {
    switch(action.type) {
        case UPDATE_WEEKLY_OPENINGS_DATA: {
            return {
                ...state,
                weeklyOpeningsData: action.newData
            }
        }
        case UPDATE_SPECIAL_OPENINGS_DATA: {
            return {
                ...state,
                specialOpeningsData: action.newData
            }
        }
        // case UPDATE_NEW_OPENING_DATA: {
        //     return {
        //         ...state,
        //         newOpeningData: action.newData
        //     }
        // }
        default:
            return state;
    }
}