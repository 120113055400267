import React from 'react';


const Tab = ({ index, label, services }) => (
    <div className='tab'>
        <input type='radio' id={`rd${index}`} name='rd' />
        <label className='tab-label' for={`rd${index}`}>{label}</label>
        <div className='tab-content'>
            <div className='row'>
                <ul className='servizi'>
                    {
                        services?.length > 0 && services.map((s, i) => (
                            <li key={i}>
                                <img src={s.img} alt='<missing image>' />
                                <p>{s.label}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
)

const BrandServices = ({ allServices, brandData }) => {

    const {
        name: brandName,
        brandActivatedServices,
        updatableServices
    } = brandData;

    const approvedUserActivatedServices = allServices.filter(service => {
        if (!service.rtBrandActivated) {
            const isSelected = typeof service.selected === 'boolean' ? service.selected : service.selected[brandName];
            return isSelected && !!updatableServices.find(s => s.brandServiceId === service.brandServiceId)
        }
        return false;
    });

    const notApprovedUserActivatedServices = allServices.filter(service => {
        if (!service.rtBrandActivated) {
            const isSelected = typeof service.selected === 'boolean' ? service.selected : service.selected[brandName];
            return isSelected && !updatableServices.find(s => s.brandServiceId === service.brandServiceId)
        }
        return false;
    });

    return (
        <div className='row center'>
            <h2> {brandName} </h2>
            <div className='row cont-servizi'>
                <div className='col'>
                    <div className='tabs'>
                        <Tab
                            index={1}
                            label='Servizi attivati dal Brand'
                            services={brandActivatedServices}
                        />
                        <Tab
                            index={2}
                            label='Servizi che hai aggiunto e sono stati approvati'
                            services={approvedUserActivatedServices}
                        />
                        <Tab
                            index={3}
                            label='Servizi che hai aggiunto e non sono stati approvati'
                            services={notApprovedUserActivatedServices}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandServices;



