import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { useStoreInfo } from "../../stores/storesInfo";
import { useStore } from "../../stores/openings";
import { useMetrics } from "../../stores/metrics";
import { updateSpecialOpeningsData } from "../../actions/openings";
import { SPECIAL_OPENINGS_URL, SPECIAL_OPENINGS_DATA_URL } from "../../constants/links";

import StoreInfo from "../StoreInfo";
import { SpecialOpening, formatH24, formatH12 } from "./Openings";
import infoLogo from "../../images/info.svg";

const page = (pageName, storeInfoLabel, backButtonVal) => ({
    name: pageName,
    storeInfoLabel,
    backButtonVal,
});

const specialOpeningsPage = () =>
    page("special_openings_list", "Inserisci Orari Speciali", "home");
const addSpecialOpeningPage = () =>
    page("add_special_opening", "Nuovo Orario Speciale", SPECIAL_OPENINGS_URL);

const SpecialOpeningsList = ({
    currentPage,
    setCurrentPage,
    specialOpenings,
    storeInfo,
    analytics,
}) => {
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

    const goToAddSpecialOpeningPage = () => setCurrentPage(addSpecialOpeningPage());

    const handleSave = (e) => {
        e.preventDefault();
        const updatedDatesAsArray = Object.entries(specialOpenings)
            .filter(([_, dateObject]) => !dateObject.deleted)
            .map(([date, dateObject]) => ({ [date]: dateObject }));
        const updatedSpecialOpenings = updatedDatesAsArray.reduce(
            (specialOpenings, date) => ({ ...date, ...specialOpenings }),
            {}
        );
        const dataToSave = { specialOpenings: updatedSpecialOpenings };
        console.log("saving data: ", dataToSave);
        setSaveButtonEnabled(false);
        axios
            .post(SPECIAL_OPENINGS_DATA_URL, dataToSave, { withCredentials: true })
            .then(() =>
                toast.success("Orari speciali salvati con successo!", {
                    className: "custom_rt",
                })
            )
            .catch((error) => {
                console.error(error);
                toast.error("Errore durante il salvataggio.");
            })
            .finally(() => setTimeout(() => setSaveButtonEnabled(true), 1000));

        // Sending analytics save event
        const category = storeInfo.customer;
        const action = `${currentPage.name}-click-save`;
        const label = `${storeInfo.storeCode}-${storeInfo.name}-${storeInfo.address}`;
        analytics.event(category, action, label);
    };

    return (
        <form onSubmit={handleSave}>
            <div className="row">
                <button className="new" onClick={goToAddSpecialOpeningPage}>
                    Aggiungi Nuovo
                </button>
            </div>
            <div className="row cont-servizi orari">
                <div className="col">
                    <div className="tabs">
                        {Object.keys(specialOpenings)
                            .filter((date) => !specialOpenings[date].deleted)
                            .sort((a, b) => {
                                if (a > b) return 1;
                                else if (b > a) return -1;
                                else return 0;
                            })
                            .map((date) => (
                                <SpecialOpening
                                    currentPage={currentPage}
                                    key={date}
                                    jsonKey={date}
                                    data={specialOpenings}
                                />
                            ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="submit" className="salva" disabled={!saveButtonEnabled}>
                    Salva
                </button>
            </div>
        </form>
    );
};

const AddSpecialOpening = ({
    currentPage,
    setCurrentPage,
    specialOpenings,
    storeInfo,
}) => {
    const goToSpecialOpeningsPage = () => setCurrentPage(specialOpeningsPage());

    const { dispatch } = useStore();
    const { analytics } = useMetrics();

    const defaultState = {
        date: "",
        closed: false,
        closing1: "",
        closing2: "",
        opening1: "",
        opening2: "",
    };
    const [newOpening, setNewOpening] = useState(defaultState);

    const handleSave = (e) => {
        e.preventDefault();
        const { date, ...rest } = newOpening;
        const newDate = { [date]: rest };
        let updatedDatesAsArray = Object.entries(specialOpenings)
            .filter(([_, dateObject]) => !dateObject.deleted)
            .map(([date, dateObject]) => ({ [date]: dateObject }));
        updatedDatesAsArray = updatedDatesAsArray.concat([newDate]);
        const updatedSpecialOpenings = updatedDatesAsArray.reduce(
            (specialOpenings, date) => ({ ...date, ...specialOpenings }),
            {}
        );
        // const dataToSave = { specialOpenings: updatedSpecialOpenings }
        // console.log('saving data: ', dataToSave);

        // updating UI
        dispatch(updateSpecialOpeningsData(updatedSpecialOpenings));

        // Sending analytics save event
        const category = storeInfo.customer;
        const action = `${currentPage.name}-click-save`;
        const label = `${storeInfo.storeCode}-${storeInfo.name}-${storeInfo.address}`;
        analytics.event(category, action, label);

        e.stopPropagation();
        goToSpecialOpeningsPage();
    };

    const handleDateChange = (e) =>
        setNewOpening((prev) => ({ ...prev, date: e.target.value }));
    const handleOpening1Change = (e) =>
        setNewOpening((prev) => ({ ...prev, opening1: formatH12(e.target.value) }));
    const handleClosing1Change = (e) =>
        setNewOpening((prev) => ({ ...prev, closing1: formatH12(e.target.value) }));
    const handleOpening2Change = (e) =>
        setNewOpening((prev) => ({ ...prev, opening2: formatH12(e.target.value) }));
    const handleClosing2Change = (e) =>
        setNewOpening((prev) => ({ ...prev, closing2: formatH12(e.target.value) }));

    const handleReset = () =>
        setNewOpening((prev) => ({
            ...defaultState,
            date: prev.date,
        }));
    const handleClose = () =>
        setNewOpening((prev) => ({
            ...defaultState,
            date: prev.date,
            closed: !prev.closed,
        }));

    return (
        <form onSubmit={handleSave}>
            <div className="cont-input">
                <input
                    class="input-bianco"
                    type="date"
                    value={newOpening.date}
                    required={true}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={handleDateChange}
                />
            </div>
            <div className="row cont-servizi orari orari-speciali">
                <div className="col">
                    <div className="tabs">
                        <div className="tab-content">
                            <div className="row">
                                <div className="info">
                                    <span>
                                        <img src={infoLogo} alt="" />
                                    </span>
                                    <p>
                                        Se lo store fa orario continuato inserisci solo i
                                        primi due orari.
                                    </p>
                                </div>
                                <div className="cont-input">
                                    <label>Orario di apertura</label>
                                    <input
                                        type="time"
                                        className="input-bianco"
                                        disabled={newOpening.closed}
                                        value={formatH24(newOpening.opening1)}
                                        required={!newOpening.closed}
                                        onChange={handleOpening1Change}
                                    />
                                </div>
                                <div className="cont-input mBottom">
                                    <label>Orario di chiusura</label>
                                    <input
                                        type="time"
                                        className="input-bianco"
                                        disabled={newOpening.closed}
                                        value={formatH24(newOpening.closing1)}
                                        required={!newOpening.closed}
                                        min={formatH24(newOpening.opening1)}
                                        onChange={handleClosing1Change}
                                    />
                                </div>
                                <div className="cont-input">
                                    <label>Orario di apertura</label>
                                    <input
                                        // id={`${jsonKey}__test-input`}
                                        type="time"
                                        className="input-bianco"
                                        disabled={newOpening.closed}
                                        value={formatH24(newOpening.opening2)}
                                        min={formatH24(newOpening.closing1)}
                                        required={false}
                                        onChange={handleOpening2Change}
                                    />
                                </div>
                                <div className="cont-input mBottom">
                                    <label>Orario di chiusura</label>
                                    <input
                                        type="time"
                                        className="input-bianco"
                                        disabled={
                                            newOpening.closed ||
                                            newOpening.opening2 === ""
                                        }
                                        value={formatH24(newOpening.closing2)}
                                        min={formatH24(newOpening.opening2)}
                                        required={newOpening.opening2 !== ""}
                                        onChange={handleClosing2Change}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="stato open"
                                    onClick={handleReset}
                                >
                                    Reset Orari
                                </button>
                                <button
                                    type="button"
                                    className={`stato ${
                                        newOpening.closed ? "closed" : "open"
                                    }`}
                                    onClick={handleClose}
                                >
                                    Chiuso
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="submit" className="salva">
                    Aggiungi
                </button>
            </div>
        </form>
    );
};

const SpecialOpenings = () => {
    const { storeInfo } = useStoreInfo();
    const { analytics } = useMetrics();
    const {
        state: { specialOpeningsData },
        dispatch,
    } = useStore();

    // keeps track of the current page
    const [currentPage, setCurrentPage] = useState(specialOpeningsPage());
    useEffect(() => {
        window.backButtonVal = currentPage.backButtonVal;
        console.log("Back Button: ", window.backButtonVal);

        if (storeInfo.data) {
            // Sending Analytics pageview & detailed event whenever the current page changes
            const info = `${storeInfo.data.storeCode}-${storeInfo.data.name}-${storeInfo.data.address}`;
            analytics.pageview(currentPage.name);
            analytics.event(storeInfo.data.customer, currentPage.name, info, {
                nonInteraction: true,
            });
        }
    }, [currentPage, storeInfo.data]);

    useEffect(() => {
        // Fetching data
        axios
            .get(SPECIAL_OPENINGS_DATA_URL, { withCredentials: true })
            .then((response) => {
                console.log(response);
                dispatch(updateSpecialOpeningsData(response.data.specialOpenings));
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div className="row orari-speciali">
            {!storeInfo.loading && !storeInfo.error && specialOpeningsData && (
                <div className="row wrap">
                    <StoreInfo
                        storeInfo={storeInfo.data}
                        label={currentPage.storeInfoLabel}
                    />
                    <div className="row center">
                        {currentPage.name === "special_openings_list" && (
                            <SpecialOpeningsList
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                storeInfo={storeInfo.data}
                                specialOpenings={specialOpeningsData}
                                analytics={analytics}
                            />
                        )}
                        {currentPage.name === "add_special_opening" && (
                            <AddSpecialOpening
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                storeInfo={storeInfo.data}
                                specialOpenings={specialOpeningsData}
                                analytics={analytics}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpecialOpenings;
