import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { getMockupDataFetchFunction, synchronizeDataFetch } from '../../services/dataFetch';
import { SERVICES_URL, SERVICES_DATA_URL } from '../../constants/links';

import StoreInfo from '../StoreInfo';

import ServicesList from './ServicesList';
import BrandServices from './BrandServices';
import AddService from './AddService';



const fetchServices = async () => {
    const res = await axios.get(SERVICES_DATA_URL, { withCredentials: true });
    return res.data;
}
const fetchData = synchronizeDataFetch(fetchServices);


// This object contains the static informations of every page related to this component
const pageDetails = (page, storeInfoLabel, backButtonVal) => ({
    name: page,
    storeInfoLabel,
    backButtonVal
});

export const servicesListPage = () => pageDetails('services_list', 'Elenco Brands', 'home');
export const brandServicesPage = () => pageDetails('brand_services', 'Servizi del Brand', SERVICES_URL);
export const addServicePage = () => pageDetails('add_service', 'Modifica Servizi', SERVICES_URL);



const Services = () => {
    // handle swap between ServiceList component, BrandServices component and AddService component
    const [currentPage, setCurrentPage] = useState(servicesListPage());
    useEffect(() => {
        window.backButtonVal = currentPage.backButtonVal;
        console.log('Back Button: ', window.backButtonVal);
    }, [currentPage]);

    // handle the brand selection in the list component
    const [selectedBrand, setSelectedBrand] = useState(undefined);
    // handle data loading status
    const [dataStatus, setDataStatus] = useState({
        loading: false,
        error: undefined,
        storeInfo: undefined,
        inputData: undefined
    });
    useEffect(() => {
        setDataStatus({ loading: true, error: undefined, storeInfo: undefined, inputData: undefined });
        fetchData()
            .then(response => {
                console.log(response);
                setDataStatus({ loading: false, error: undefined, storeInfo: response.storeInfo, inputData: response.data })
            })
            .catch(error => setDataStatus({ loading: false, error, storeInfo: undefined, inputData: undefined }));
    }, []);

    return (
        <>
            { !dataStatus.loading && dataStatus.error && <div>Errore: {dataStatus.error}</div>}
            {
                !dataStatus.loading && !dataStatus.error && dataStatus.inputData && dataStatus.storeInfo && (
                    <div className='row servizi'>
                        <div className='row wrap'>
                            <StoreInfo storeInfo={dataStatus.storeInfo} label={currentPage.storeInfoLabel} />
                            {
                                currentPage.name === 'services_list' && (
                                    <ServicesList
                                        inputData={dataStatus.inputData}
                                        setSelectedBrand={setSelectedBrand}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )
                            }
                            {
                                currentPage.name === 'brand_services' && (
                                    <BrandServices
                                        allServices={dataStatus.inputData.allServices}
                                        brandData={dataStatus.inputData.brands.find(brandObject => brandObject.name === selectedBrand)}
                                    />
                                )
                            }
                            {
                                currentPage.name === 'add_service' && (
                                    <AddService
                                        inputData={dataStatus.inputData}
                                        setInputData={(updatedData) => setDataStatus({...dataStatus, inputData: updatedData})}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}


export default Services;