import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { SERVICES_DATA_URL } from '../../constants/links';

import { servicesListPage } from './Services';



// services sorting function
const sortingFunction = (s1, s2) => {
    if (s1.name > s2.name) return 1;
    else if (s1.name < s2.name) return -1;
    else return 0;
}

// get services list from input Data
const getServicesList = (inputData) => inputData.brands.reduce((services, brandObject) => {
    const service_ids = services.map(service => service.brandServiceId);
    const brandServices = brandObject.updatableServices.filter(service => !service_ids.includes(service.brandServiceId));
    return services.concat(brandServices);
}, []);


const updateInputData = (inputData, id) => {
    const findServiceAndUpdate = (list) => list.map(s => s.brandServiceId === id ? {...s, selected: !s.selected, updated: !s.updated} : s);

    const inputCopy = {...inputData}
    inputCopy.allServices = findServiceAndUpdate(inputCopy.allServices);
    for (let i=0, len=inputCopy.brands.length; i<len; ++i) {
        inputCopy.brands[i].updatableServices = findServiceAndUpdate(inputCopy.brands[i].updatableServices);
    }
    return inputCopy;
}


const AddService = ({ inputData, setInputData, setCurrentPage }) => {

    const [ updatedInputData, setUpdatedInputData ] = useState(inputData);
    const [ services, setServices ] = useState(getServicesList(inputData));

    const handleServiceClick = (service) => {
        const updatedInput = updateInputData(updatedInputData, service.brandServiceId);
        console.log('updatedInput: ', updatedInput);
        setServices(getServicesList(updatedInput));
        setUpdatedInputData(updatedInput);
    }

    const handleSave = () => {
        console.log('saving services...', services);
        const data = {
            allServices: services
        }
        axios.post(SERVICES_DATA_URL, data, { withCredentials: true })
            .then(response => {
                toast.success('Servizi salvati con successo!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    className: "custom_rt"
                });
            })
            .catch(error => {
                toast.error('Errore durante il salvataggio.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
            });
        setInputData(updatedInputData);
        setCurrentPage(servicesListPage());
    }

    return (
        <div className='row center'>
            <p className='intro'>Trovati {services.length} servizi disponibili:</p>
            <div className='row cont-servizi'>
                <ul className='servizi aggiungi size2'>
                    {
                        services.length > 0 && (services.sort(sortingFunction)).map(service => (
                            <li
                                key={service.brandServiceId}
                                className={service.selected ? 'selected' : 'not-selected'}
                                onClick={() => handleServiceClick(service)}
                            >
                                <img src={service.img} alt='< missing-image >' />
                                <p>{service.label}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className='row'>
                <button className='salva' onClick={handleSave}>Salva</button>
            </div>
        </div>
    );
}

export default AddService;