import React, { useState, useEffect } from 'react';

import { fetchStoreData } from '../services/dataFetch';

import StoreInfo from './StoreInfo';
import home from '../images/home.jpg';
import accedi from '../images/accedi.jpg';
import orari from '../images/orari.jpg';
import reset from '../images/reset.jpg';
import salva from '../images/salva.jpg';
import servizi from '../images/servizi.jpg';


export default () => {

  const [dataStatus, setDataStatus] = useState({
    loading: false,
    error: undefined,
    storeInfo: undefined
  });
  useEffect(() => {
    setDataStatus({ ...dataStatus, loading: true });
    fetchStoreData()
      .then(data => setDataStatus({ loading: false, error: undefined, storeInfo: data }))
      .catch(error => setDataStatus({ loading: false, error, storeInfo: undefined }));
  }, []);


  return (
    <div class="row orari">
      {
        !dataStatus.loading && dataStatus.error && <div>Errore: {dataStatus.error}</div>
      }
      {
        !dataStatus.loading && !dataStatus.error && dataStatus.storeInfo && (
          <div class="row wrap">
            <StoreInfo storeInfo={dataStatus.storeInfo} label="Help desk" />
            <div class="row center">
              <div class="row cont-help">
                <h2>Help APP RetailTune</h2>
                <p>L’APP RetailTune consente di aggiornare in mobilità gli orari di apertura e chiusura dei negozi e di specificare, per ciascun brand gestito nel negozio, i servizi offerti. I dati inseriti sull’APP saranno aggiornati su tutti i touch-point gestiti dalla piattaforma per il negozio. L’accesso all’APP è possibile mediante due modalità. Una volta che l’utente è riconosciuto verrà mostrata la pagina iniziale dove sono presenti le icone associate alle funzionalità al momento disponibili e quelle di prossimo rilascio. Queste ultime non sono selezionabili e sono mostrate in grigio.</p>
                <div class="cont-img"><img src={home} alt="" /></div>
                <h2>Accesso all’APP</h2>
                <p>Si può accedere all’APP sia con le credenziali fornite allo Store Manager nel momento in cui è stata attivata l’utenza sulla piattaforma, sia mediante un’email preventivamente certificata sulla piattaforma. A questa email nel momento in cui ci si richiede l’accesso verrà inviato un codice OTP (One Time Password) che funge da password di accesso utilizzabile per una sola volta. Contattare l’amministratore per configurare questa seconda modalità di accesso.</p>
                <div class="cont-img"><img src={accedi} alt="" /></div>
                <h2>Orari</h2>
                <p>Questa sezione permette di gestire l’inserimento degli orari settimanali standard di apertura e chiusura e i giorni di chiusura. Dopo aver selezionato un giorno della settimana, compilare gli orari di ingresso e uscita. Nel caso di orario continuato compilare solo i primi due campi.</p>
                <div class="cont-img"><img src={orari} alt="" /></div>
                <p>Per azzerare i dati orari precedemente inseriti e inserirne di nuovi premere il pulsante Reset Orari. <br />
          Per indicare che nel giorno selezionato il negozio è chiuso premere il tasto Chiuso </p>
                <div class="cont-img"> <img src={reset} alt="" /></div>
                <p>Per confermare le modifiche effettuate premere il pulsante Salva posizionato in fondo allo schermo.</p>
                <div class="cont-img"><img src={salva} alt="" /></div>
                <h2>Orari speciali</h2>
                <p>In aggiunta agli orari standard è possibile inserire orari o chiusure speciali per il giorno selezionato sul calendario. Gli orari inseriti o l’indicazione di chiusura vanno a sostituirsi agli orari standard definiti per il relativo giorno della settimana. Dopo aver selezionato una data del calendario, analogamente a quanto fatto per gli orari standard, si possono inserire gli orari di apertura e chiusura e l’indicazione del negozio chiuso.</p>
                <h2>Servizi</h2>
                <p>Questa sezione, laddove abilitata, permette di selezionare i servizi che vengono visualizzati nei touch-point del negozio. Nella pagina iniziale vengono elencati i brand associati al negozio, per ciascuno di essi, dopo aver cliccato sull’icona associata, vengono mostrati i servizi associati. Il pulsante Aggiungi servizi permette di selezionare dall’elenco i servizi che si intende aggiungere al negozi affinché vengano mostrati nelle schede negozio dei touch-point. I criteri con cui i servizi scelti vengono spiegati di seguito.</p>

                <div class="cont-img"><img src={servizi} alt="" /></div>


                <h2>Servizi attivati dal Brand</h2>
                <p>Qui vengono mostrati i servizi indicati come obbligatori dal gestore del brand e sono quindi predefiniti sulla piattaforma per qualsiasi negozio associato al brand. L’utente dell’APP non può modificarli.</p>
                <h2>Servizi che hai aggiunto e sono stati approvati</h2>
                <p>I servizi selezionati con l’APP e che rientrano tra quelli consentiti dal brand vengono visualizzati in questo elenco. L’utente dell’APP può togliere i servizi visualizzati qui in quanto facoltativi.</p>
                <h2>Servizi che hai aggiunto e non sono stati approvati</h2>
                <p>I servizi selezionati con l’APP e che non sono previsti dal brand vengono visualizzati in questo elenco.</p>
                <h2>Supporto</h2>
                <p>Per segnalazioni relative all'uso dell'app contatta il tuo servizio di supporto</p>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}