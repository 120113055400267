import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { useStoreInfo } from '../../stores/storesInfo';
import { useMetrics } from '../../stores/metrics';
import { useStore } from '../../stores/openings';
import { updateWeeklyOpeningsData } from '../../actions/openings';
import { synchronizeDataFetch } from '../../services/dataFetch';
import { WEEKLY_OPENINGS_URL, WEEKLY_OPENINGS_DATA_URL } from '../../constants/links';

import StoreInfo from '../StoreInfo';
import { WeeklyOpening, formatH12 } from './Openings';




// const fetchOpenings = async () => {
//     const res = await axios.get(WEEKLY_OPENINGS_DATA_URL, { withCredentials: true });
//     return res.data;
// }
// const fetchData = synchronizeDataFetch(fetchOpenings);

const currentPage = {
    name: "weekly_openings",
    label: "Inserisci orari"
}

const WeeklyOpenings = () => {

    const { storeInfo } = useStoreInfo();
    const { analytics } = useMetrics();
    const { state: { weeklyOpeningsData }, dispatch } = useStore();

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

    useEffect(() => {
        axios.get(WEEKLY_OPENINGS_DATA_URL, { withCredentials: true })
            .then(response => {
                console.log(response);
                dispatch(updateWeeklyOpeningsData(response.data))
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if (storeInfo.data) {
            // Sending Analytics pageview & detailed event for this page
            const info = `${storeInfo.data.storeCode}-${storeInfo.data.name}-${storeInfo.data.address}`;
            analytics.pageview(currentPage.name);
            analytics.event(storeInfo.data.customer, currentPage.name, info, { nonInteraction: true });
        }
    }, [storeInfo.data]);

    const saveData = e => {
        e.preventDefault();
        console.log('saving data: ', weeklyOpeningsData);
        setSaveButtonEnabled(false);
        axios.post(WEEKLY_OPENINGS_DATA_URL, weeklyOpeningsData, { withCredentials: true })
            .then(() => toast.success('Orari salvati con successo!', { className: "custom_rt" }))
            .catch(error => {
                console.error(error);
                toast.error('Errore durante il salvataggio.');
            })
            .finally(() => setTimeout(() => setSaveButtonEnabled(true), 1000));

        // Sending analytics save event
        const category = storeInfo.data.customer;
        const action = `${currentPage.name}-click-save`;
        const label = `${storeInfo.data.storeCode}-${storeInfo.data.name}-${storeInfo.data.address}`;
        analytics.event(category, action, label);
    }

    return (
        <div className="row orari">
            {
                !storeInfo.loading && !storeInfo.error && weeklyOpeningsData && (
                    <div className="row wrap">
                        <StoreInfo storeInfo={storeInfo.data} label={currentPage.label} />
                        <div className="row center">
                            <form onSubmit={saveData}>
                                <div className="row cont-servizi orari">
                                    <div className="col">
                                        <div className="tabs">
                                            {
                                                Object.keys(weeklyOpeningsData).map(day => (
                                                    <WeeklyOpening
                                                        currentPage={currentPage}
                                                        key={day}
                                                        jsonKey={day}
                                                        data={weeklyOpeningsData}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <button type='submit' className="salva" disabled={!saveButtonEnabled}>Salva</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default WeeklyOpenings;