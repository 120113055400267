


export const UPDATE_WEEKLY_OPENINGS_DATA = 'UPDATE_WEEKLY_OPENINGS_DATA';
export const UPDATE_SPECIAL_OPENINGS_DATA = 'UPDATE_SPECIAL_OPENINGS_DATA';
export const UPDATE_NEW_OPENING_DATA = 'UPDATE_NEW_OPENING_DATA';


export const updateWeeklyOpeningsData = (newData) => ({
    type: UPDATE_WEEKLY_OPENINGS_DATA,
    newData
});

export const updateSpecialOpeningsData = (newData) => ({
    type: UPDATE_SPECIAL_OPENINGS_DATA,
    newData
});

export const updateNewOpeningData = (newData) => ({
    type: UPDATE_NEW_OPENING_DATA,
    newData
});