import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { StoreInfoProvider } from './stores/storesInfo';
import { MetricsProvider } from './stores/metrics';
import Services from './components/services/Services';
import WeeklyOpenings from './components/openings/WeeklyOpenings';
import SpecialOpenings from './components/openings/SpecialOpenings';
import HelpDesk from './components/HelpDesk';

import { OpeningsDataProvider } from './stores/openings';

import './App.css';

const refpage = document.cookie.replace(/(?:(?:^|.*;\s*)refpage\s*\=\s*([^;]*).*$)|^.*$/, '$1');

const OPENINGS = 'openings';
const SPECIAL_OPENINGS = 'special-openings';
const SERVICES = 'services';
const HELP_DESK = 'helpdesk'

const App = () => {
  console.log(refpage);

  return (
    <StoreInfoProvider>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2000}
      />
      <MetricsProvider>
        {
          refpage === OPENINGS && (
            <OpeningsDataProvider>
              <WeeklyOpenings />
            </OpeningsDataProvider>
          )
        }
        {
          refpage === SPECIAL_OPENINGS && (
            <OpeningsDataProvider>
              <SpecialOpenings />
            </OpeningsDataProvider>
          )
        }
        {
          refpage === SERVICES && (
            <Services />
          )
        }
        {
          refpage === HELP_DESK && (
            <HelpDesk />
          )
        }
      </MetricsProvider>
    </StoreInfoProvider>
  )
};

// switch (refpage) {
//   // case 'brands':
//   //   return <Brands />
//   case 'openings':
//     return (
//       <OpeningsDataProvider>
//         <WeeklyOpenings />
//       </OpeningsDataProvider>
//     );
//   case 'services':
//     return <Services />;
//   case 'special-openings':
//     return (
//       <OpeningsDataProvider>
//         <SpecialOpenings />
//       </OpeningsDataProvider>
//     );
//   case 'helpdesk':
//     return <HelpDesk />;
//   default:
//     return;
// }

export default App;
