import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { STORE_INFO_URL } from '../constants/links';


const StoreInfoContext = React.createContext();

export const StoreInfoProvider = ({ children }) => {

    const [data, setData] = useState({
        loading: true,
        error: undefined,
        data: undefined
    });
    useEffect(() => {
        axios.get(STORE_INFO_URL, { withCredentials: true })
            .then(response => setData({
                loading: false,
                error: undefined,
                data: response.data
            })).catch(error => setData({
                loading: false,
                error,
                data: undefined
            }));
    }, []);

    return (
        <StoreInfoContext.Provider value={{ storeInfo: data }}>
            { children }
        </StoreInfoContext.Provider>
    )
}

export const useStoreInfo = () => useContext(StoreInfoContext);
