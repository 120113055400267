import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';


const GOOGLE_ANALYTICS_USER_ID = "UA-91869037-4";
const DEFAULT_TRACKER_NAME = 'gtm1';

const gaOptions = {
    name: DEFAULT_TRACKER_NAME,
    allowLinker: true,
    cookieDomain: "auto",
    siteSpeedSampleRate: "100"
}

const createDefaultAnalyticsHandler = (analyticsUserId) => {

    ReactGA.initialize(analyticsUserId, {
        alwaysSendToDefaultTracker: false,
        gaOptions
    });

    const trackerNames = [DEFAULT_TRACKER_NAME];

    return {
        event: async (category, action, label, options = {}) => {
            ReactGA.event({ category, action, label, ...options }, trackerNames);
        },
        pageview: async (page) => {
            ReactGA.pageview(page, trackerNames);
        }
    }
}

const MetricsContext = React.createContext();

export const MetricsProvider = ({ children }) => {

    const analytics = createDefaultAnalyticsHandler(GOOGLE_ANALYTICS_USER_ID);

    const [metrics] = useState({
        analytics
    });

    return (
        <MetricsContext.Provider value={metrics}>
            { children}
        </MetricsContext.Provider>
    )
}

export const useMetrics = () => useContext(MetricsContext);
