import React from 'react';

import { brandServicesPage, addServicePage } from './Services';




const ServicesList = ({ inputData, setSelectedBrand, setCurrentPage }) => {

    const handleGoToBrandServicesPage = (brandName) => {
        setSelectedBrand(brandName);
        setCurrentPage(brandServicesPage());
    }

    const handleGoToAddServicesPage = () => {
        setCurrentPage(addServicePage());
    }

    return (
        <div className='row center'>
            <ul className='brands-list'>
                {
                    inputData.brands?.length > 0 && inputData.brands.map(b => (
                        <li
                            key={b.name}
                            onClick={() => handleGoToBrandServicesPage(b.name)}
                        > {b.name}</li>
                    ))
                }
            </ul>
            <p className='testo1'>Oppure</p>
            <ul className='aggiungi-nuovo'>
                <li onClick={handleGoToAddServicesPage}>
                    <h2>Aggiungi Servizi <i className='fa fa-plus'></i></h2>
                    {/* <a href='#'>
                        
                    </a> */}
                </li>
            </ul>
        </div>
    )
}

export default ServicesList;