import React from 'react';

const StoreInfo = ({ storeInfo, label }) => (
    <div className="topArea">
        <p className="nome-store">{storeInfo.name}</p>
        <p className="indirizzo">{storeInfo.address}</p>
        <h1>{label}</h1>
    </div>
)

export default StoreInfo;




// const fetchStoreData = async (authToken) => {
//     const res = await axios.get('https://test.retailtune.com/rtapp/store/info', {
//         withCredentials: true,
//         headers: { "Authorization": `Bearer ${authToken}` }
//     });
//     return res.data;
// }


// const StoreInfo = ({ authToken, label }) => {
//     const [dataStatus, setDataStatus] = useState({
//         loading: false,
//         data: undefined,
//         error: undefined
//     });
//     useEffect(() => {
//         if (!dataStatus.error) {
//             setDataStatus({ loading: true, data: undefined, error: undefined });
//             fetchStoreData(authToken)
//                 .then(data => setDataStatus({ loading: false, data: { ...data, token: authToken }, error: undefined }))
//                 .catch(error => setDataStatus({ loading: false, data: undefined, error }))
//         }
//     }, [authToken, dataStatus.error]);

//     return (
//         <>
//             { dataStatus.error && <div>Errore durante il recupero dei dati negozio: {dataStatus.error}</div>}
//             { !dataStatus.error && (
//                 <div className="topArea">
//                     { !dataStatus.loading && dataStatus.data && <p className="nome-store">{dataStatus.data.name}</p>}
//                     { !dataStatus.loading && dataStatus.data && <p className="indirizzo">{dataStatus.data.address}</p>}
//                     <h1>{label}</h1>
//                 </div>
//             )}
//         </>
//     );
// }

// export default StoreInfo;

